<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
    <div>
        <p>{{messageBody}}</p>
    </div>
</div>
<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" mat-dialog-close>Close</button>
    <button class="btn btn-primary" (click)="flagRetryAllVisits()"> Confirm</button>
</div>
