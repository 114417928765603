import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';

import { ApiService } from '../../../services/api.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-add-cpt-code-record-modal',
    templateUrl: './add-cpt-code-record-modal.component.html',
    styleUrls: ['./add-cpt-code-record-modal.component.scss']

})
export class AddCPTCodeRecordModalComponent implements OnInit {
    public model = {
        codeType: "",
        code: "",
        quantity: 1,
        modifier: "",
        modifier2: "",
        action: "Add",
        visitId: 0
    }
    public modifiers: any = []
    public isLoading: boolean = false
    public msgAlert: string = ""

    constructor(
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<AddCPTCodeRecordModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private snotifyService: SnotifyService,
    ) {
  
    }

    ngOnInit(): void {
        this.model.visitId = this.data.visitId
        this.modifiers = this.data.modifiers
    }

    save() {
        if (!this.model.codeType) {
            this.msgAlert = "CPT code type must be selected."
        }
        if (!this.model.code) {
            this.msgAlert = "Code name field can not be empty."
            return
        }
        if (!this.model.quantity || this.model.quantity <= 0) {
            this.msgAlert = "Code quantity shouldn't be lesss than 1"
            return
        }
        this.isLoading = true
        this.apiService.addHistoryCode(this.model).subscribe((r) => {
            this.isLoading = false;
            if (r.success) {
                this.dialogRef.close('saved')
                this.snotifyService.success(r.responseText)
            } else {
                //r.responseText
                this.snotifyService.error(r.responseText)
            }
        }, err => {
            this.snotifyService.error("Server error, record is not saved.")
        })
    }

}
