<div class="row" style="display:flex; align-items: center; justify-content: space-between">
    <div class="col-lg-12">
        <h1>Code Record</h1>
    </div>
</div>

<div class="row top-buffer">
    <div class="col-md-12">
        <div class="box box-widget">
            <div class="box-header with-border">
                <h3 class="box-title">Filters</h3>
            </div>
            <div class="box-body" *ngIf="!isLoadingFilter">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Visit Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="filter.visitStartDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Visit End Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="filter.visitEndDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Code Review Rule</mat-label>
                            <mat-select multiple [(ngModel)]="selectedRules">
                                <mat-option *ngFor="let rule of codeReviewRules" [value]="rule">
                                    {{rule.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Tag</mat-label>
                            <mat-select multiple [(ngModel)]="filter.tags">
                                <mat-option *ngFor="let tag of listTagsFilter" [value]="tag">
                                    {{tag}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Fin Classes</mat-label>
                            <mat-select multiple [(ngModel)]="filter.finClassIds">
                                <mat-option *ngFor="let fc of dataFilter.finClasses" [value]="fc.text">
                                    {{fc.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Clinic</mat-label>
                            <mat-select multiple [(ngModel)]="filter.clinicIds">
                                <mat-option *ngFor="let fc of dataFilter.clinics" [value]="fc.text">
                                    {{fc.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Physician</mat-label>
                            <mat-select multiple [(ngModel)]="filter.physicianIds">
                                <mat-option *ngFor="let fc of dataFilter.physicians" [value]="fc.id">
                                    {{fc.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Office Key</mat-label>
                            <mat-select multiple [(ngModel)]="filter.officeKeys">
                                <mat-option *ngFor="let fc of dataFilter.officeKeys" [value]="fc.text">
                                    {{fc.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" style="display: flex;align-items: center;justify-content: center;">
                    <div class="col-md-6" style="text-align: start">
                        <mat-checkbox class="example-margin" [(ngModel)]="filter.isUnflagged">Inlcude unflagged items
                        </mat-checkbox>
                    </div>
                    <div class="col-md-6" style="text-align: end">
                        <button mat-raised-button color="primary" class="mr-1" [disabled]="!isShowSearch"
                            (click)="searchClass()">Search</button>
                        <button class="mr-1" mat-raised-button (click)="refetch()">Refetch</button>
                    </div>
                </div>
            </div>
            <div class="box-body" *ngIf="isLoadingFilter">
                <span>Loading...</span>
            </div>
        </div>
    </div>
</div>

<div class="row top-buffer" style="padding: 0 15px">
    <div class="col-12">
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="first group" *ngIf="queryVisitId == null">
                <button type="button" class="btn btn-default" [disabled]="position == 0"
                    (click)="previous()">Previous</button>
                <button type="button" class="btn btn-default" [disabled]="position + 1 == total"
                    (click)="next()">Next</button>
            </div>
            <button type="button" class="btn btn-success" (click)="sendMessage()">
                Message
                <ng-container *ngIf="visitReturn && visitReturn.id && visitReturn.returnStatus==0">&nbsp; (Sent To Provider)</ng-container>
                <ng-container *ngIf="visitReturn && visitReturn.id && visitReturn.returnStatus==1">&nbsp; (Update Received)</ng-container>
                <ng-container *ngIf="visitReturn && visitReturn.id && visitReturn.returnStatus==2">&nbsp; (Update Merged)</ng-container>
            </button>

            <button type="button" class="btn btn-info" [disabled]="isDisabled" id="savePage" (click)="saveChart()">
                <ng-container *ngIf="savingVisit">
                    Saving...
                </ng-container>
                <ng-container *ngIf="!savingVisit">
                    Save
                </ng-container>
            </button>
            <button type="button" class="btn btn-primary" (click)="gotoHome()">Home</button>
            <button type="button" class="btn btn-warning" *ngIf="visitId > 0" (click)="showTag()">Tag</button>
            <!-- <button type="button" class="btn btn-rule" *ngIf="visitId > 0" [disabled]="visitRules.length == 0" (click)="showVisitRule()">Rules</button> -->
        </div>
    </div>
    <div class="col-12" class="rule-body">
        <p>Applied Rules: <strong *ngIf="visitRules?.length == 0">No Data!</strong></p>

        <div class="rule-content">
            <mat-chip-list #chipList appearance="fill">
                <mat-chip *ngFor="let item of visitRules; let i=index" [selectable]="true" [removable]="true"
                    (removed)="removeRuleChip(item, i)" name="chips">{{item}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <!-- <input placeholder="Enter Tag" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes " [matChipInputAddOnBlur]="true "
                    (matChipInputTokenEnd)="addTag($event) " /> -->
            </mat-chip-list>
        </div>
    </div>
    <div class="col-12" style="margin-top: 10px">
        You are viewing {{total > 0 ? position + 1 : position}} of {{total}} Records. VisitId : {{visitId}} &nbsp;
        <ng-container *ngIf="visitId > 0">
            <mat-slide-toggle class="example-margin" color="primary" [(ngModel)]="visit.chart.isFlaged"
                              (ngModelChange)="changeFlagged()">
                Flaged
            </mat-slide-toggle>
        </ng-container>
        &nbsp;
        <ng-container *ngIf="visitId > 0">
            <mat-slide-toggle class="example-margin" color="primary" [(ngModel)]="showOcrFormat"  toggle="changeFlagged()">
                Show OCR
            </mat-slide-toggle>
        </ng-container>
    </div>
</div>

<div class="row top-buffer">
    <div class="col-lg-5">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div>
                    <h3>Patient Chart</h3>
                </div>
                <div class="visit-info" *ngIf="visitId > 0">
                    <div class="row-info">
                        <span>{{visit.patFirstName}} {{visit.patLastName}}</span>
                        <span>MRM: {{visit.visitId}}</span>
                        <span>AMD: {{visit.amdVisitId}}</span>
                        <span>EMR: {{visit.visitEMRId}}</span>
                    </div>
                    <div class="row-info">
                        <span>DOB: {{visit.birthday}}</span>
                        <span>DOS: {{visit.serviceDate | date: 'MM/dd/YYYY'}}</span>
                        <span>{{visit.officeKey}} <strong *ngIf="visit.finClass">({{visit.finClass}})</strong></span>
                        <span>{{visit.physicianName}} <strong *ngIf="visit.clinicId">({{visit.clinicId}})</strong></span>
                    </div>
                </div>
            </div>
            <div class="panel-body" *ngIf="!showOcrFormat">
                <div class="row">
                    <div class="col-md-12">
                        <div style="width:100%;" class="btn-toolbar pull-right" role="toolbar" aria-label="Toolbar with button groups"
                             matTooltip="Toolbar with button groups">
                            <div style="float:left" *ngIf="base64String">
                                <button type="button" class="btn btn-default" (click)="openPDFInNewTab(base64String)">Open on new tab</button>
                            </div>
                            <div class="btn-group" style="float:right" role="group" aria-label="fucntion group">
                                <button type="button" class="btn btn-default" (click)="uploadChart()">Replace</button>
                            </div>
                            <div class="btn-group" style="float:right" role="group" aria-label="navigation group"
                                 *ngIf="srcChartImage != ''">
                                <button type="button" class="btn btn-primary" id="btnPrint"
                                        (click)="printChart()">
                                    Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row top-buffer">
                    <!--<object *ngIf="srcChartPdf != ''" id="pdf" height='800' style="width: 100%" type="application/pdf" [data]="srcChartPdf"></object>-->
                    <div *ngIf="isLoadingChartPdf" style="padding: 10px 20px;">
                        <p>Loading...</p>
                    </div>
                    <div class="iframe-content">
                        <iframe [ngClass]="!srcChartPdf ? 'd-none' : ''" id="ifrm-chart" height='800' style="width: 100%"></iframe>
                    </div>
                    <div class="viewer text-center" style="height:800px; width:100%;" id="chartviewer"
                         *ngIf="srcChartImage != '' && !isLoadingChartPdf">
                        <img  alt="" class="img-thumbnail img-fluid" id="chartimage" [src]="srcChartImage">
                    </div>
                </div>
            </div>
            <div class="panel-body" *ngIf="showOcrFormat">
                <div class="row top-buffer">
                    <ngx-datatable class="material custom-table" [rows]="ocrData" [headerHeight]="50"
                                   [footerHeight]="50" rowHeight="auto">

                        <ngx-datatable-column name="Section" [width]="150">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span> {{ row.sectionName }}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Sub Section" [width]="150">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{ row.subSectionName }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Value" [width]="300">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{ row.sectionText }}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>

        <div class="panel panel-default">
            <div class="panel-heading">
                <div>
                    <h3>Addendum</h3>
                </div>              
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="btn-group" role="group" aria-label="fucntion group">
                            <button type="button" class="btn btn-default" (click)="openAddendumSetting()">{{visitFileByte?'Edit':'Add'}} Addendum</button>
                            <button *ngIf="visitFileByte" type="button" class="btn btn-default" (click)="openPDFInNewTab(visitFileByte)">Open on new tab</button>
                            <button *ngIf="visitFileByte" type="button" class="btn btn-danger" (click)="removeVisitPatientDocument()">Delete</button>
                        </div>
                    </div>
                </div>
                <div class="row top-buffer">
                    <div *ngIf="loadingSubmitVisitForm" style="padding: 10px 20px;">
                        <p>Loading...</p>
                    </div>
                    <div class="iframe-content" id="visitFileByte" >
                        <!--<iframe [ngClass]="!visitFileByte ? 'd-none' : ''"  src="data:application/pdf;base64,{{visitFileByte}}&embedded=true"  height='800' style="width: 100%" type="application/pdf"></iframe>-->
                        <!--<object data="data:application/pdf;base64,{{visitFileByte}}" type="application/pdf"></object>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-7">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3>Code Records</h3>
            </div>
            <div class="panel-body">
                <div class="box" *ngIf="visitCodingNote">
                    <label>Notes:</label>
                    <small *ngIf="savingNote" style="margin-left:15px">saving.. </small>
                    <small id="saved-note" style="margin-left:15px; display:none; color:forestgreen">saved </small>
                    <br />
                    <textarea rows="4" style="width:100%" [(ngModel)]="visitCodingNote.notes" (focusout)="onFocusOutEvent($event)"></textarea>
                </div>
                <div class="box box-danger">
                    <div class="box-header with-border">
                        <h3 class="box-title">CPTCode Records</h3>
                    </div>



                    <div class="box-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn btn-default pull-right" (click)="addCPTCode()">
                                    Add CPT
                                    Code
                                </button>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px">
                            <div class="col-xs-12" style="padding: 0">
                                <!--<table id="CPTCodeTable" class="table table-striped"></table>-->
                                <ngx-datatable class="material custom-table" [rows]="cptCodes" [headerHeight]="50"
                                               [footerHeight]="50" rowHeight="auto" [externalPaging]="true" [count]="cptPage.total"
                                               [offset]="cptPage.currentPage" [limit]="cptPage.limit"
                                               [loadingIndicator]="isCptLoading" (page)="cptSetPage($event)">

                                    <ngx-datatable-column name="Quantity" [width]="100">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span *ngIf="!row.isEdit"> {{ row.quantity }}</span>
                                            <ng-container *ngIf="row.isEdit">
                                                <input type="text" class="form-control validate input-table"
                                                       [(ngModel)]="row.quantity">
                                            </ng-container>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="CPT Type" [width]="150">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row.codeType }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Code" [width]="150">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span *ngIf="!row.isEdit">{{ row.codeName }}</span>
                                            <ng-container *ngIf="row.isEdit">
                                                <input type="text" class="form-control validate input-table"
                                                       [(ngModel)]="row.codeName">
                                            </ng-container>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Modifier" [width]="100">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span *ngIf="!row.isEdit">{{ row.modifierCode }}</span>
                                            <ng-container *ngIf="row.isEdit">
                                                <select class="form-control input-table" [(ngModel)]="row.modifierCode">
                                                    <option *ngFor="let item of modifiers" [value]="item.id">
                                                        {{item.id}}
                                                    </option>
                                                </select>
                                            </ng-container>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Modifier 2" [width]="100">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span *ngIf="!row.isEdit">{{ row.modifierCode2 }}</span>
                                            <ng-container *ngIf="row.isEdit">
                                                <select class="form-control input-table"
                                                        [(ngModel)]="row.modifierCode2">
                                                    <option *ngFor="let item of modifiers" [value]="item.id">
                                                        {{item.id}}
                                                    </option>
                                                </select>
                                            </ng-container>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Description" [width]="100">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row.shortDescription }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Action" [width]="200">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div style="display: flex; align-items: center; justify-content: center"
                                                 *ngIf="!row.isEdit">
                                                <button type="button" class="btn btn-default btn-sm"
                                                        style="margin-right: 5px" (click)="editCodeRow(row)">
                                                    <span class="glyphicon glyphicon-pencil"></span> EDIT
                                                </button>
                                                <button type="button" class="btn btn-danger btn-sm"
                                                        (click)="deleteCodeRow(row)">
                                                    <span class="glyphicon glyphicon-trash"></span> DELETE
                                                </button>
                                            </div>
                                            <div style="display: flex; align-items: center; justify-content: center"
                                                 *ngIf="row.isEdit">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right: 5px" (click)="updateCodeRow(row)">
                                                    <span class="glyphicon glyphicon-ok-sign"></span> UPDATE
                                                </button>
                                                <button type="button" class="btn btn-danger btn-sm"
                                                        (click)="cancelCodeRow(row)">
                                                    <span class="glyphicon glyphicon-remove"></span> CANCEL
                                                </button>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>

                    </div>

                    <div class="box box-warning">
                        <div class="box-header with-border">
                            <h3 class="box-title">IcdCode Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-xs-12" style="text-align: end;">
                                    <button type="button" style="margin-right: 10px;" class="btn btn-default" id="btnIcdAdd" (click)="addIcdCode()">Add ICD Code</button>
                                    <!-- <button type="button" [diabled]="isDisabledOrderIcd" class="btn btn-small btn-primary" (click)="changeOrder('Icd')">Change Order</button> -->
                                </div>
                            </div>
                            <div class="row" style="margin-top: 10px">
                                <div class="col-xs-12" style="padding: 0">
                                    <!--<table id="IcdCodeTable" class="table table-striped"></table>-->
                                    <div class="table-scroll">
                                        <table class="table table-code">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col" style="text-align: center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="isIcdLoading">
                                                <tr>
                                                    <td colspan="3" style="text-align: center;">Loading...</td>
                                                </tr>
                                            </tbody>
                                            <tbody cdkDropList (cdkDropListDropped)="dropIcdCode($event)" *ngIf="!isIcdLoading">
                                                <tr *ngFor="let row of icdCodes" cdkDrag class="cursor-pointer">
                                                    <td>
                                                        <span *ngIf="!row.isEdit">{{ row.codeName }}</span>
                                                        <ng-container *ngIf="row.isEdit">
                                                            <mat-form-field class="example-full-width" appearance="fill" style="width: 100%">
                                                                <input matInput [(ngModel)]="row.codeName" (keyup)="searchIcd($event)" debounce="600" [matAutocomplete]="auto">
                                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                                    <mat-option *ngFor="let option of arrIcd" [value]="option[0]">
                                                                        <small><b>Code: </b> </small> <span>{{option[0]}}</span> |
                                                                        <small><b>Name: </b> </small> <span>{{option[1]}}</span>
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </mat-form-field>
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        {{ row.description }}
                                                    </td>
                                                    <td>
                                                        <div style="display: flex; align-items: center; justify-content: center" *ngIf="!row.isEdit">
                                                            <button type="button" class="btn btn-default btn-sm" style="margin-right: 5px" (click)="editCodeRow(row)">
                                                                <span class="glyphicon glyphicon-pencil"></span> EDIT
                                                            </button>
                                                            <button type="button" class="btn btn-danger btn-sm" (click)="deleteCodeRow(row)">
                                                                <span class="glyphicon glyphicon-trash"></span> DELETE
                                                            </button>
                                                        </div>
                                                        <div style="display: flex; align-items: center; justify-content: center" *ngIf="row.isEdit">
                                                            <button type="button" class="btn btn-primary btn-sm" style="margin-right: 5px" (click)="updateCodeRow(row)">
                                                                <span class="glyphicon glyphicon-ok-sign"></span> UPDATE
                                                            </button>
                                                            <button type="button" class="btn btn-danger btn-sm" (click)="cancelCodeRow(row)">
                                                                <span class="glyphicon glyphicon-remove"></span> CANCEL
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xs-12 footer">
                                    <span>Total: {{icdPage.total}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="row top-buffer" *ngIf="visitId <= 0" style="padding: 0 15px">
    <div class="col-12" style="text-align: center">
        <span style="font-size: 16px; font-weight: 600">No data!</span>
    </div>
</div>