import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Chart } from 'chart.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { DialogLoadingComponent } from '../dialog-loading/dialog-loading.component';
import { ProcessLogErrorDetailModalComponent } from '../modal/process-log-error-detail-modal/process-log-error-detail-modal.component';

import * as moment from 'moment';
import * as _ from 'lodash';
//import { MqttService, IMqttMessage, IMqttServiceOptions } from 'ngx-mqtt';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public canvas: any;
    public ctx: any;
    public rowsProcessLog: any = []
    public rowsProcessLog_old: any = []
    public serverMachines: any = []
    public physicians: any = []

    public loadingTable: boolean = false;
    public loadingTableMissing: boolean = false;
    public loadingTableServerMachine: boolean = false;
    public isLoadingChart: boolean = false;
    public isLoadingPhysician: boolean = false;
    public pendingOCRCount: number = 0;
    public pendingRecentOCRCount: number = 0;

    public companyEMR : any 

    syncingWithAmd = false;
    public page = {
        pageSize: 10, 
        pageNumber: 0,
        count: 0
    }
    public pageSever = {
        pageSize: 10, 
        pageNumber: 0,
        count: 0
    }
  

    @ViewChild('processChart') processChart;

    constructor(
        private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private matDialog: MatDialog,
        //private _mqttService: MqttService
    ) {
        //const mqttServiceOptions: IMqttServiceOptions = {
        //    hostname: "localhost",
        //    path: '/mqtt',
        //    protocol: "wss",
        //    port: 65094
        //};

        //this._mqttService = new MqttService(mqttServiceOptions);

    }

    ngOnInit(): void {
        this.getUserCompanyEMR();
        this.getJobProcessPaginationOld(1, 10)
        this.loadDataProcess(this.page.pageNumber, this.page.pageSize)
        this.loadServiceMachine(1, 10)
        this.loadPhysicianProviderEmpty()
        this.checkPendingOCRCharts()
        this.checkCurrentYearPendingOCRCharts()
        // this.loadDataChart()


        //this._mqttService.connect();
        //this._mqttService.observe('ImportFileProgress').subscribe((message: IMqttMessage) => {
        //    console.log('mqtt message: ', message.payload.toString());

        //    var result = JSON.parse(message.payload.toString());
        //    if (result.processId) {
        //        const index = _.findIndex(this.rowsProcessLog_old, { processId: result.processId });
        //        if (index != -1) {
        //            this.rowsProcessLog_old[index].status = result.status;
        //            this.rowsProcessLog_old[index].processMqtt = `${result.processCount}/${result.totalRecord}`;
        //        }
        //    }
        //});

    }

    ngAfterViewInit() {
       this.loadDataChart()
    }

    getUserCompanyEMR() {
        this.apiService.getUserCompanyEMR().subscribe(result => {
            this.companyEMR = result;
            console.log("companyEMR", this.companyEMR)
        })
    }


    checkPendingOCRCharts() {
        this.apiService.getPendingOCRCount().subscribe((r) => {
            this.pendingOCRCount = r
            console.log(r)
        })
    }

    checkCurrentYearPendingOCRCharts() {
        this.apiService.getCurrentYearPendingOCRCount().subscribe((r) => {
            this.pendingRecentOCRCount = r
            console.log(r)
        })
    }
    loadDataChart(){
        this.isLoadingChart = true
        this.apiService.getChartVisitImportLog().subscribe((data) => {      

            let dayLabels = _.map(data, 'label')
            let countPV = _.map(data, 'countFromPv')
            let countAMD = _.map(data, 'countSentToAMD')
            this.isLoadingChart = false
            this.canvas = this.processChart.nativeElement;
            this.ctx = this.canvas.getContext('2d');
            let myChart = new Chart(this.ctx, {
                type: 'line',
                data: {
                    labels: dayLabels,
                    datasets: [
                        {
                            label: "From " + this.companyEMR?.emR_Name,
                            backgroundColor: "rgba(60,141,188,0.2)",
                            borderColor: "rgba(60,141,188,1)",  
                            data: countPV
                        },
                        {
                            label: "Sent to AMD",
                            backgroundColor: "rgba(255,0,0,0.2)",
                            borderColor: "rgba(255,0,0,1)",
                            data: countAMD
                        }
                    ]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                suggestedMin: 0,
                            }
                        }]
                    }
                }
            });
        })
    }

    getJobProcessPaginationOld(pageNumber, pageSize){
        this.loadingTable = true
        this.apiService.getJobProcessPaginationOld(pageNumber, pageSize).subscribe((r) => {
            //console.log(r)
            this.rowsProcessLog_old = r.records
            _.each(this.rowsProcessLog_old, (e) => {

                let numError = 0
                let arrResult = e.processResult ? e.processResult.split(' of total ') : ''
                let arrtotal = arrResult ? arrResult[1].split(' were') : ''
                numError = (parseInt(arrtotal[0]) - parseInt(arrResult[0])) ?? 0
                e.numError = numError
                e.processedDate = this.formatDateToCST(e.processedDate)
            })
            //console.log('this.rowsProcessLog_old', this.rowsProcessLog_old)
            this.page.count = r.total
            this.loadingTable = false
        })
    }

    loadDataProcess(currentPage, pageSize) {
        this.loadingTableMissing = true
        this.apiService.getJobProcessPaginationNew(currentPage, pageSize).subscribe((r) => {
            this.rowsProcessLog = r.results
            _.each(this.rowsProcessLog, (e) => {
                e.isCheckFileExist = false
            })
            this.loadingTableMissing = false
        })
    }

    loadServiceMachine(pageNumber, pageSize){
        this.loadingTableServerMachine = true
        this.apiService.getServiceMachine(pageNumber - 1, pageSize).subscribe((r: any) => {
           this.serverMachines = r.results
           this.pageSever.count = r.total
           this.loadingTableServerMachine = false
        })
    }

    loadPhysicianProviderEmpty(){
        this.isLoadingPhysician = true
        this.apiService.getPhysicianAmdProviderEmpty().subscribe((r) => {
            this.physicians = r 
            this.isLoadingPhysician = false;
        })
    }

    findMissingProcess(processLog, currentPage) {
        let currentDate = moment().format();
        //let currentDate = '2021-07-15 07:00:28.040'
        let resultDate = []
        var results = []

        var index = 0;
        var n = 10;
        if (currentPage == 0) {
            index = 1
            n = 10
        } else {
            index = currentPage*10 + 1 
            n = index + 9
        }
        let j = 0
        for (let i = index; i <= n; i++) {
            j += 1;
            let preDay = moment(currentDate).add(-i, 'days');
            resultDate.push(preDay.format()) //test

            let existing = _.find(processLog, (e) => {
                return moment(e.processedDate).isSame(moment(preDay), 'day')
            })
            
            if (!existing) {
                console.log('j', j)
                let item =  {
                        processId: 0,
                        processedDate: moment(preDay).format("YYYY-MM-DD"),
                        sourceFileName: "Missing",
                        processResult: "Missing",
                        successFlag: false
                };
                results.push(item)
                let item2 = {
                    processId: processLog[j-1].processId,
                    processedDate: moment(processLog[j-1].processedDate).format("YYYY-MM-DD, hh:mm:ss"),
                    sourceFileName: processLog[j-1].sourceFileName,
                    processResult: processLog[j-1].processResult,
                    successFlag: processLog[j-1].successFlag
                };
                results.push(item2)
            } else {
                let item = {
                    processId: existing.processId,
                    processedDate: moment(existing.processedDate).format("YYYY-MM-DD, hh:mm:ss"),
                    sourceFileName: existing.sourceFileName,
                    processResult: existing.processResult,
                    successFlag: existing.successFlag
                };
                results.push(item)
            }
        }
        return results 
    }

    setPage(pageInfo) {
        this.page.pageNumber = pageInfo.offset 
        /* this.loadDataProcess(this.page.pageNumber, this.page.pageSize)*/
        this.getJobProcessPaginationOld(this.page.pageNumber+1, 10)
    }

    setPageServerMachine(pageInfo){
       this.pageSever.pageNumber = pageInfo.offset 
       this.loadServiceMachine(this.pageSever.pageNumber, this.pageSever.pageSize)
    }

    getFileName(row) {
        let fileDate = moment(row.processedDate).add(-1, 'days').format("MM_DD_YY")
        let filePath = `newExportCOMMD_${fileDate}.xml`;    
        return filePath;
    }

    checkFileExist(row) {
        this.openLoading()
        let fileDate = moment(row.processedDate).add(-1, 'days').format("MM_DD_YY")
        let filePath = `newExportCOMMD_${fileDate}.xml`;
        this.apiService.checkFileExist(filePath).subscribe((r) => {
            this.closeLoading()
            if (r == true) {
                row.isCheckFileExist = true
                this.showSnackBar("File exists")
            } else {
                row.isCheckFileExist = false
                this.showSnackBar("File doesn't exist")
            }
        })
    }

    rerunImport(row){
       this.openLoading()
        let fileDate = moment(row.processedDate).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        }).add(-1, 'days');
        let currentDate = moment();
       var offsetDay = currentDate.diff(fileDate, 'days') // 1
       let verifyFileName = moment(row.processedDate).add(-1, 'days').format("MM_DD_YY")
       var payload = {
        offsetDay: offsetDay,
        verifyFileName: verifyFileName
        }
       this.apiService.rerunFileImport(payload).subscribe((r) => {
            this.closeLoading()
            if(r){
                this.loadDataProcess(this.page.pageNumber, this.page.pageSize)
            }
       })
    }

    rerunImportFileUpdate(row) {
        this.openLoading()
        let fileDate = moment(row.processedDate ?? row.processedDate).add(-1, 'days').utc();
        let currentDate = moment().utc()
        var offsetDay = currentDate.diff(fileDate, 'days')
        let verifyFileName = moment(row.processedDate).add(-1, 'days').format("MM_DD_YY")
        var payload = {
            offsetDay: offsetDay,
            verifyFileName: verifyFileName
        }
        this.apiService.rerunImportFileForUpdate(payload).subscribe((r) => {
            this.closeLoading()
            if (r) {
                if (this.page.pageNumber == 0) {
                    this.page.pageNumber++;
                }
                this.getJobProcessPaginationOld(this.page.pageNumber, this.page.pageSize)
            }
        })
    }

    public showSnackBar(message) {
        this._snackBar.open(message)
        setTimeout(() => {
            this._snackBar.dismiss()
        }, 1000);
    }

    openLoading() {
        this.matDialog.open(DialogLoadingComponent, {
            minWidth: '150px',
            disableClose: true
        });
    }

    closeLoading() {
        this.matDialog.closeAll()
    }

    showErrorDetail(row){
        if(row.numError <= 0){
            return
        }
        const dialogRef = this.matDialog.open(ProcessLogErrorDetailModalComponent, {
            minWidth: '900px',
            data: {
                processId: row.processId,
                fileName: row.sourceFileName
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            
        });
    }

    syncWithAmd() {
        this.syncingWithAmd = true;
        this.apiService.updatePhysicianAmdProvideId().subscribe(r => {
            this.syncingWithAmd = false;
            this.loadPhysicianProviderEmpty()
        })
    }

    ngOnDestroy(): void {
        //this._mqttService.disconnect();
    }

    formatDateToCST(dateTimeStr: any): string {
        const date = moment(dateTimeStr);
        const cstDate = moment(date).add(-6, 'hours');
        const formattedDate = moment(cstDate).format('MM/DD/YYYY HH:mm');
        return formattedDate + ' (CST)';
    }

  
}
