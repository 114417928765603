import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnotifyService } from 'ng-snotify';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { moveItemInArray } from '@angular/cdk/drag-drop';

//Service
import { ApiService } from '../../services/api.service';
import { SharedService } from '../../services/shared.service';

//Modal
import { DialogLoadingComponent } from '../dialog-loading/dialog-loading.component';
import { AddCPTCodeRecordModalComponent } from '../modal/add-cpt-code-record-modal/add-cpt-code-record-modal.component';
import { AddICDCodeRecordModalComponent } from '../modal/add-icd-code-record-modal/add-icd-code-record-modal.component';
import { MessagePhysicianModalComponent } from '../modal/message-physician-modal/message-physician-modal.component';
import { UploadChartModalComponent } from '../modal/upload-chart-modal/upload-chart-modal.component';
import { VisitTagModalComponent } from '../modal/visit-tag-modal/visit-tag-modal.component';
import { VisitRuleSetModalComponent } from '../modal/visit-rule-set-modal/visit-rule-set-modal.component';
import { zip } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { AddendumSettingModalComponent } from '../../components/modal/addendum-setting/addendum-setting.component';
import { error } from 'console';

@Component({
    selector: 'app-code-record-view',
    templateUrl: './code-record-view.component.html',
    styleUrls: ['./code-record-view.component.scss']

})
export class CodeRecordViewComponent implements OnInit {
    public isLoadingFilter: boolean = false;
    public codeReviewRules = [];
    public dataFilter: any;
    public selectedRules: any = [];
    public isShowSearch: boolean = true;
    public visit: any = {}
    visitCodingNote: any ;

    public visitTags: any = []
    public visitId: number;
    public queryVisitId: null;
    public position: number = 0;
    public total: number = 0;
    public srcChartPdf: SafeResourceUrl
    public srcChartImage: string = ""

    public modifiers: any = []
    public verbage = 'I attest that I am not changing the material in the medical chart, but updating the codes to accurately represent the procedures, diagnosis, and documentation provided in the medical record. and clicking accept logs this attestation and who was the logged in user for those changes to the code list.'
    public isDisabled: boolean = true;
    public currentUser: any;

    public ocrData: any = []
    public icdCodes = []
    public cptCodes = []
    public arrIcd = []
    public isIcdLoading: boolean = false;
    public isCptLoading: boolean = false;

    public loadingSubmitVisitForm = false;
    public visitFileByte: any;

    public visitAddendumDocument = {} as any;
    public showOcrFormat: boolean = false;

    public icdPage = {
        currentPage: 0,
        limit: 10,
        type: 'Icd',
        total: 0
    }
    public cptPage = {
        currentPage: 0,
        limit: 10,
        type: "CPT", 
        total: 0
    }

    public filter = {
        visitStartDate: moment().subtract(1, 'year').toDate(),
        visitEndDate: new Date(),
        clinicIds: [],
        finClassIds: [],
        physicianIds: [],
        officeKeys: [],
        ruleIds: [],
        ruleNames: [],
        isUnflagged: false,
        tags: []
    };
    public listTagsFilter: any = []
    public visitRules: any = []
    private dialogLoading: any;
    public isDisabledOrderIcd: boolean = true;
    public savingNote = false;
    public isLoadingChartPdf: boolean = false;
    public savingVisit = false;
    public fileName = "";
    public base64String = "";
    public visitReturn: any = {};
    constructor(
        private apiService: ApiService,
        private dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private snotifyService: SnotifyService,
        private router: Router,
        public sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private sharedService: SharedService,
    ) {
        this.queryVisitId = this.activatedRoute.snapshot.queryParams.visitId
        console.log('this.queryVisitId', this.queryVisitId)
    }

    ngOnInit(): void {
        this.getCurrentUserInfo()
        this.getDataFilter()
        this.loadDataVisit()
        this.updateVisitRuleSet()
    }

    getDataFilter() {
        this.isLoadingFilter = true;
        zip(
            this.apiService.getDataBulkUpdate(),
            this.apiService.getCodeReviewRuleActive(),
            this.apiService.getVisitTags()
        ).subscribe(([dataFilter, codeRule, tags]) => {
            this.dataFilter = dataFilter
            let groupRuleName = _.groupBy(codeRule, 'ruleName')
            let ruleName = Object.keys(groupRuleName)
            this.codeReviewRules = []
            _.each(ruleName, (name) => {
                let itemRule = {
                    name: name,
                    ruleIds: _.map(groupRuleName[name], 'id'),
                    isSelected: false
                }
                this.codeReviewRules.push(itemRule)
            })
            this.listTagsFilter = tags
            this.isLoadingFilter = false
        })
    }

    loadDataVisit() {
        this.getModifiers()
        this.loadCode()
    }

    loadCode() {
        this.openLoading()
        this.srcChartImage = ""
        this.srcChartPdf = ""
        this.isLoadingChartPdf = true
        this.apiService.loadCodeFilter(this.filter, this.position, this.queryVisitId).subscribe((r) => {
            this.visit = r.result;
            //console.log('this.visit', this.visit)
            this.getVisitChartAddendum(this.visit.visitId);
            this.getVisitReturn(this.visit.visitId);
            this.total = r.total
            this.visitId = this.visit ? this.visit.visitId : 0
            this.loadNotes();
            this.visit.tags = []
            if (this.visit && this.visit.chart) {
                this.fileName = this.visit.chart.chartName.split('.').slice(0, -1).join('.');
                if (this.visit.chart.chartType === ".tif") {
                    this.loadPatientChart(this.visit.chart.chartName)
                } else if (this.visit.chart.emrId == 2 ) {
                    this.getChartDocumentEMR(this.visit.chart.chartName);
                }
                else
                {
                    this.isLoadingChartPdf = false
                    this.srcChartImage = 'data:image/jpeg;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(this.visit.chart.fileBinary) as any).changingThisBreaksApplicationSecurity;
                    this.base64String = this.srcChartImage;
                }
                this.visitTags = this.visit.tag?.length > 0? JSON.parse(this.visit.tag) : this.visit.appliedRuleNames
            }
            this.loadHistoryIcdCode(this.icdPage.currentPage, this.icdPage.limit)
            this.loadHistoryCptCode(this.cptPage.currentPage, this.cptPage.limit)
            this.loadVisitRules()
            this.loadChartOcr()
            this.closeLoading()
        }, err => {
            this.isLoadingChartPdf = false;
            this.closeLoading()
        })
    }
    loadNotes() {
        this.apiService.getCodingNote(this.visitId).subscribe(r => {
            if (r) {
                this.visitCodingNote = r;
            } else {
                this.visitCodingNote = { notes: '', visitId: this.visitId }
            }
           
        })
    }

    getVisitReturn(visitId) {
        this.apiService.getVisitReturnByVisitId(visitId).subscribe(res => {
            this.visitReturn = res;
        })
    }

    searchClass() {
        let ruleIds = []
        // if (this.selectedRules.length > 0) {
        //     _.each(this.selectedRules, (e) => {
        //         ruleIds = ruleIds.concat(e.ruleIds)
        //     })
        // }
        // this.filter.ruleIds = ruleIds
        this.filter.ruleNames = this.selectedRules?.length > 0 ? _.map(this.selectedRules, 'name') : []
        this.position = 0
        this.queryVisitId = null
        this.loadCode()
    }

    refetch() {
        this.filter = {
            visitStartDate: moment().subtract(1, 'year').toDate(),
            visitEndDate: new Date(),
            clinicIds: [],
            finClassIds: [],
            physicianIds: [],
            officeKeys: [],
            ruleIds: [],
            ruleNames: [],
            isUnflagged: false,
            tags: []
        };
        this.loadCode()
    }

    previous() {
        this.position -= 1
        this.loadCode()
    }
    next() {
        this.position += 1
        this.loadCode()
    }

    changeFlagged() {
        this.isDisabled = false
    }

    toggleShowOcr() {
        this.showOcrFormat = !this.showOcrFormat
    }

    saveChart() {
        let payload = {
            visitId: this.visitId,
            flaged: this.visit.chart.isFlaged
        }
        this.savingVisit = true
        this.apiService.saveChartCode(payload).subscribe((r) => {
            this.savingVisit = false
            if (confirm("changes are saved successfully. Click ok to get the next record.")) {
                //window.location.reload()
                this.position += 1
                this.loadCode()
            }
        }, err => {
            this.savingVisit = false
        })
    }

    printChart() {
        var content = document.getElementById('chartviewer').innerHTML;
        const templeWin = `
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Patient Chart</title>

                    <style>
                    body { -webkit-print-color-adjust: exact !important; }
                    img { margin: 0; padding: 0}
                    @media print{
                      .print-break{
                        page-break-before:always;
                      }
                    }
                    </style>
                    </head>
                    <body>
                    ${content}
                    </body>
                    </html>
                    `
        const printWin = window.open('', '', 'width=700,height=700');

        printWin.document.open();
        printWin.document.write(templeWin);
        printWin.document.close();
        setTimeout(() => {
            printWin.focus();
            printWin.print();
            printWin.close();
        }, 500);
    }

    getCurrentUserInfo() {
        this.apiService.getCurrentUserInfo().subscribe((r) => {
            this.currentUser = r.result
        })
    }

    loadHistoryIcdCode(page, limit) {
        let type = "Icd"
        this.isIcdLoading = true
        this.apiService.getHistoryCodeByType(type, page, limit, this.visitId, true).subscribe((r) => {
            this.isIcdLoading = false
            this.icdCodes = r.records || []
            this.icdPage.total = r.total
            _.each(this.icdCodes, (e) => {
                e.isEdit = false
            })
        })
    }

    loadPatientChart(fileName) {
        this.srcChartPdf = null
        this.apiService.getPatientChartFromPDF(fileName).subscribe((base64String: string) => {
            this.isLoadingChartPdf = false
            if(base64String){
            this.srcChartPdf = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(base64String) as any).changingThisBreaksApplicationSecurity;
            // top.document.getElementById('ifrm').setAttribute("src", this.srcChartPdf);
             this.base64String = base64String;
            const byteArray = new Uint8Array(
                atob(base64String)
                  .split('')
                  .map((char) => char.charCodeAt(0))
              );
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl  = window.URL.createObjectURL(blob);
            var blob_iframe = document.querySelector('#ifrm-chart') as any;
            blob_iframe.src = blobUrl;
           }
        })
    }

    loadHistoryCptCode(page, limit) {
        let type = "CPT"
        this.isCptLoading = true
        this.apiService.getHistoryCodeByType(type, page, limit, this.visitId, false).subscribe((r) => {
            this.cptCodes = r.records || []
            this.cptPage.total = r.total
            this.isCptLoading = false
            _.each(this.cptCodes, (e) => {
                e.isEdit = false
            })
        })
    }

    loadChartOcr() {
        this.apiService.getVistChartOCRData(this.visitId).subscribe((r) => {
            this.ocrData = r || []
            console.log(r)
        })
    }

    loadVisitRules(){
        this.apiService.getVisitRules(this.visitId).subscribe((r) => {
            this.visitRules = r
        })
    }

    getModifiers() { 
        this.apiService.getModifiers().subscribe((r) => {
            this.modifiers = r
        })
    }

    icdSetPage(pageInfo) {
        this.loadHistoryIcdCode(pageInfo.offset, pageInfo.limit)
    }

    cptSetPage(pageInfo) {
        console.log('pageInfo', pageInfo)
        this.loadHistoryCptCode(pageInfo.offset, pageInfo.limit)
    }

    addCPTCode() {
        const dialogRef = this.dialog.open(AddCPTCodeRecordModalComponent, {
            minWidth: '600px',
            data: {
                visitId: this.visitId,
                modifiers: this.modifiers
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                this.loadHistoryCptCode(this.cptPage.currentPage, this.cptPage.limit)
            }
        });
    }

    addIcdCode() {
        const dialogRef = this.dialog.open(AddICDCodeRecordModalComponent, {
            minWidth: '600px',
            data: {
                visitId: this.visitId,
                modifiers: this.modifiers
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                this.loadHistoryIcdCode(this.cptPage.currentPage, this.cptPage.limit)
            }
        });
    }

    editCodeRow(row) {
        row.isEdit = true
    }

    deleteCodeRow(row) {
        let payload = {
            visitId: this.visitId,
            codeType: row.codeType, 
            visitCodeHistoryId: row.id
        }
        if (confirm('Are you sure to delete code ' + row.codeName + '?')) {
            this.apiService.deleteHistoryCode(payload).subscribe((r) => {
                if (r.success) {
                    this.snotifyService.success("Deleted successfully!")
                    if (row.codeType == "CPTCode") {
                        this.cptCodes = _.filter(this.cptCodes, (e) => {return e.id != row.id })
                    }
                    if (row.codeType == "IcdCode") {
                        this.icdCodes = _.filter(this.icdCodes, (e) => { return e.id != row.id })
                    }
                }
            }, err => {
                this.snotifyService.error("Server error, record is not saved.")
            })
        }
    }

    updateCodeRow(row) {
        alert(this.verbage)
        this.apiService.updateHistoryCode(row, this.visitId).subscribe((r) => {
            if (r.success) {
                this.snotifyService.success("Updated successfully!")
                row.isEdit = false
                if(row.codeType ="Icd"){
                   this.loadHistoryIcdCode(this.icdPage.currentPage, this.icdPage.limit)
                }
            }
        }, err => {
            this.snotifyService.error("Server error, record is not saved.")
        })
    }

    cancelCodeRow(row) {
        row.isEdit = false
    }

    gotoHome() {
        window.location.href = "/record/review"
    }

    sendMessage() {
        const dialogRef = this.dialog.open(MessagePhysicianModalComponent, {
            minWidth: '800px',
            data: {
                visitId: this.visitId,
                officeKey: this.visit.officeKey,
                userEmail: this.currentUser.userName,
                physicianEmail: this.visit.physicianEmail,
                emailSubject: this.visit.emailSubject,
                emailDescription: this.visit.emailBody,
                visit: this.visit
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
               
            }
            this.getVisitReturn(this.visitId);
        });
    }

    uploadChart() {
        const dialogRef = this.dialog.open(UploadChartModalComponent, {
            minWidth: '800px',
            data: {
                visitId: this.visitId,
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            this.loadCode()
        });
    }

    showTag(){
        const dialogRef = this.dialog.open(VisitTagModalComponent, {
            minWidth: '800px',
            data: {
                visitId: this.visitId,
                visitTags: this.visitTags
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.loadCode()
        });
    }

    dropIcdCode(event){
        moveItemInArray(this.icdCodes, event.previousIndex, event.currentIndex);
        this.isDisabledOrderIcd = false
        this.changeOrder('Icd')
    }

    changeOrder(type){
        let payload = {
            codes: [],
            visitId: this.visitId,
            type: type
        }
        let message = " "
        if(type == 'Icd'){
            payload.codes = _.map(this.icdCodes, (e) => {return e.codeName})
            message ="Change order of ICD succesfully"
        }
        this.openLoading()
        this.apiService.changeOrderVisitCode(payload).subscribe((r) => {
            this.closeLoading()
            this.showSnackBar(message)
        })
    }

    searchIcd(event) {
        if (event.target.value.length > 0) {
            this.apiService.getIcds(event.target.value).subscribe((r) => {
                if (r) {
                    this.arrIcd = r[3]
                }
            })
        }
    }

    showSnackBar(message) {
        this._snackBar.open(message)
        setTimeout(() => {
            this._snackBar.dismiss()
        }, 1000); 
        
    }

    openLoading() {
      this.dialogLoading = this.dialog.open(DialogLoadingComponent, {
            minWidth: '150px',
            disableClose: true
        });
    }

    closeLoading() {
        this.dialogLoading.close()
    }

    showVisitRule(){
        const dialogRef = this.dialog.open(VisitRuleSetModalComponent, {
            minWidth: '800px',
            data: {
                visitId: this.visitId,
                visitRules: this.visitRules
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.loadCode()
        });
    }
   
    updateVisitRuleSet(){
        this.sharedService.visitRuleSetsEmitted.subscribe((r) => {
            this.visitRules = r
        })
    }

    onFocusOutEvent(ev) {
        this.savingNote = true;
        this.apiService.createUpdateCodingNote({ visitId: this.visitId, notes: this.visitCodingNote.notes }).subscribe(r => {
            this.savingNote = false;
            $('#saved-note').show()
            setTimeout(() => {
                $('#saved-note').hide()
            }, 2000)
        })
    }

    removeRuleChip(ruleName, index) {
        if (confirm('You are about to delete a rule, are you sure?')) {
          this.openLoading()
          this.apiService.removeVisitRuleSet(ruleName, this.visitId).subscribe((r) => {
            this.visitRules = _.filter(this.visitRules, (v, i) => {
              return i != index
            })
            this.closeLoading()
            this.showSnackBar('Removed Successfully!')
          }, err => {
            this.closeLoading()
            this.showSnackBar('Removed Unsuccessfully!')
          })
        } 
    }

    openPDFInNewTab(base64String) {

        //const base64Data = _.replace(base64String, /^data:application\/pdf;base64,/, '');

        //console.log

        const byteArray = new Uint8Array(
            atob(base64String)
                .split('')
                .map((char) => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.write(`<iframe width='100%' height='100%' src='${blobUrl}'></iframe`);
    }

    openAddendumSetting() {
        const dialogRef = this.dialog.open(AddendumSettingModalComponent, {
            minWidth: '350px',
            minHeight: '350px',
            height: 'auto',
            width: 'auto',          
            data: {
                visitId: this.visitId ?? this.visit.id,
                isEdit: (this.visitFileByte != null && this.visitFileByte != "") ? true : false
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getVisitChartAddendum(this.visit.visitId)
            }
        });
    }

    getVisitChartAddendum(visitId, fileName = `ChartAddendum_${this.visit.pvLogNum}.pdf`) {
        this.loadingSubmitVisitForm = true;
        this.apiService.getVisitChartAddendum(visitId, fileName).subscribe(r => {
            this.visitAddendumDocument = r;

            if (this.visitAddendumDocument.fileByte != null && this.visitAddendumDocument.fileByte != "") {
                this.visitFileByte = (this.sanitizer.bypassSecurityTrustResourceUrl(this.visitAddendumDocument.fileByte) as any).changingThisBreaksApplicationSecurity;
                document.getElementById('visitFileByte').innerHTML = `<iframe id="scaled-frame" src="data:application/pdf;base64,${this.visitFileByte}" height="620" style="width: 100%;"></iframe>`;
            }
    
            this.loadingSubmitVisitForm = false;
        }, error => {
            this.loadingSubmitVisitForm = false;
        })
    }

    getChartDocumentEMR(chartName) {
        this.isLoadingChartPdf = true;

        this.apiService.getChartDocument(chartName).subscribe(r => {
            this.srcChartPdf = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(r.fileByte) as any).changingThisBreaksApplicationSecurity;
            // top.document.getElementById('ifrm').setAttribute("src", this.srcChartPdf);
            this.base64String = r.fileByte;
            const byteArray = new Uint8Array(
                atob(r.fileByte)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            );
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = window.URL.createObjectURL(blob);
            var blob_iframe = document.querySelector('#ifrm-chart') as any;
            blob_iframe.src = blobUrl;

            this.isLoadingChartPdf = false;
        }, error => {
            this.isLoadingChartPdf = false;
        })
    }

    removeVisitPatientDocument() {
        this.loadingSubmitVisitForm = true;

        var visitPatientDocument = {
            visitId: this.visit.visitId,
            patDocId: this.visitAddendumDocument.patDocId,
        };

        this.apiService.removeVisitPatientDocument(visitPatientDocument).subscribe(r => {
            this.loadingSubmitVisitForm = false;
            this.visitFileByte = null;
            this.visitAddendumDocument.fileByte = null;
            document.getElementById('visitFileByte').innerHTML = ``;
        }, error => {
            this.loadingSubmitVisitForm = false;
        })
    }
}
